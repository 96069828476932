.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  // border: 1px solid red;
}